﻿import $ from "jquery";

$("[data-toggle-button]").each(function () {
  var toggleButtonTarget = $(this).attr("data-toggle-target");
  $(toggleButtonTarget).toggle(false);

  $(this).on("click", function () {
    $(toggleButtonTarget).toggle();
  });
});
