﻿import "../vendor/jquery-ui/jquery-ui";
import $ from "jquery";
import { isChrome } from "./../typescript/Vgr.Util";

$(document).ready(function () {
  // Ta bort alla display:none och ersätter det med vgr-visually-hidden.
  // Detta är för att JQuery UI sätter display:none på alla flikar när sidan laddas som inte fungera med sökningen i webbläsaren.

  var allPanel = $(document.querySelectorAll(".ui-tabs-panel"));
  for (var i = 0; i <= allPanel.length; i++) {
    if (allPanel[i] !== undefined) {
      if (allPanel[i].hasAttribute("style") === "display=none") {
        $(allPanel[i]).removeAttr("style");
        $(allPanel[i]).addClass("vgr-visually-hidden");
      } else {
        $(allPanel[i]).removeClass("vgr-visually-hidden");
      }
      if ($(allPanel[i]).css("display") === "none") {
        $(allPanel[i]).css("display") === "";
        $(allPanel[i]).addClass("vgr-visually-hidden");
      }
      continue;
    }
  }
  $(document).keyup(function (ev) {
    if (ev.which == 37 || ev.which == 39) {
      $(ev.target).children("a").click();
    }
  });
  // ta bort vgr-visually-hidden vid en click
  $(".ui-tabs-anchor").on("click", function (event) {
    var panel = document.querySelectorAll(".ui-tabs-panel");
    for (i = 0; i <= panel.length; i++) {
      if (panel[i] !== undefined) {
        // JQuery UI lägger på display:none när man växlar tab , behöver ta bort den igen
        if (panel[i].hasAttribute("style") === "display=none") {
          $(panel[i]).removeAttr("style");
          $(panel[i]).addClass("vgr-visually-hidden");
        }

        if ($(panel[i]).css("display") === "none") {
          $(panel[i]).css("display", "block");
          $(panel[i]).addClass("vgr-visually-hidden");
        }

        if (
          $(panel[i]) !== undefined &&
          $(this).attr("href") === "#" + $(panel[i]).attr("id") + ""
        ) {
          if ($(panel[i]).hasClass("vgr-visually-hidden")) {
            $(panel[i]).removeClass("vgr-visually-hidden");
          }
        }

        continue;
      }
    }
  });

  if ($("[data-vgr-tabs]").length > 0) {
    // "selectionchange" works differentlt on Chrome and we cannot search/open content of the area.
    if (!isChrome()) {
      // Add an event listener that is called whenever an element is clicked or selected etc.
      // We do this because when a user searches the web page (ctrl+f) and finds a result inside an tabs area, the content should be visible.

      document.addEventListener("selectionchange", function (e) {
        var selection = document.getSelection();
        if (
          $(selection.anchorNode) !== undefined &&
          $(selection.anchorNode) !== null
        ) {
          var $dataContent = $(selection.anchorNode.parentNode).closest(
            "[data-tabcontent]"
          );

          // Check that the selected content is inside an tab content
          if ($dataContent !== null && $dataContent.length > 0) {
            var id = $dataContent.attr("id");

            var tab = $(document).find(".ui-tabs-anchor");
            for (var i = 0; i < tab.length; i++) {
              if ($(tab[i]).attr("href") === "#" + id + "") {
                $dataContent.removeClass("vgr-visually-hidden");
                $(tab[i]).click();
              }

              continue;
            }
          }
        }
      });
    }
  }
});
