﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.OutDatedBrowser', {
    options: {

    },

    // Constructor
    _create: function () {
        navigator.browserInfo = (function () {
            var ua = navigator.userAgent, tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE ' + (tem[1] || '');
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

            return { 'browser': M[0], 'version': parseInt(M[1]) };
        })();



        if ((navigator.browserInfo.browser === 'MSIE' && navigator.browserInfo.version <= 9) || (navigator.browserInfo.browser === 'Safari' && navigator.browserInfo.version <= 8) || navigator.userAgent.match(/SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i)) {
            var el = document.getElementById('outdatedbrowser');
            el.style.display = 'block';
        }

    },
    _destroy: function () {
    }

});