﻿import $ from "jquery";

$(document).ready(function () {
  if (document.getElementById("javaScriptEnabled") !== null) {
    document.getElementById("javaScriptEnabled").className = "vgr-hidden";
  }
  if (document.getElementById("JavaScriptDisabled") !== null) {
    document.getElementById("JavaScriptDisabled").className = "";
  }

  $("#confirm-booking").click(function () {
    let message = $(this).data("confirmation-message");
    let form = $(this).closest("form");

    if (!message || !form) return false;

    if (confirm(message)) {
      form.submit();
      return true;
    } else {
      return false;
    }
  });
});
