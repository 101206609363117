﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

// Dokumentera att vi väljer att inte använda display none för att man ska kunna söka 

$.widget('customer.expandBlock', {
    options: {
    },
    variables: {

    },
    // Constructor
    _create: function () {
        this._setupClickEvents();
        this._setupKeyboardEvents();

        if ($(this.element).find('[data-startopen]').length > 0) {
            this.element.toggleClass('expand-block--open');

            var $expandableContent = $(this.element).find('[data-expandblockcontent]');
            $expandableContent.toggleClass('expand-block--not-show');
        }

        // Set the aria-expand attribute to false upon creation. It defaults to true in the markup due to no-js.
        $(this.element).find('.expand-block__clickable-row').attr('aria-expanded', this._isExpanded());
        $(this.element).find('[role="tab"]').attr('aria-expanded', this._isExpanded());
        $(this.element).find('.minimize').attr('aria-label', "länk för att minimera den expanderbar yta");
        var contentId = $(this.element).find('[data-expandblockcontent]').attr('id');
        $(this.element).find('.expand-block__toggle-clickable-row').attr('aria-controls', contentId);


    },
    _destroy: function () {
    },
    _setupClickEvents: function () {
        var self = this;
        $('.expand-block__clickable-row', this.element).click(function () {

            self.element.toggleClass('expand-block--open');
     
            var $expandableContent = $(self.element).find('[data-expandblockcontent]');

            $expandableContent.toggleClass('expand-block--not-show');
            if ($(this).attr('aria-expanded') == 'false') {

                $expandableContent.attr('aria-expanded', true);
                $expandableContent.attr('aria-hidden', false);
                $(this).attr('aria-expanded', true);
                $(this).attr('data-isexpanded', true);

            } else {

                $expandableContent.attr('aria-expanded', false);
                $expandableContent.attr('aria-hidden', true);
                $(this).attr('aria-expanded', false);
                $(this).attr('data-isexpanded', false);
            }

                $(self.element).find('[tabindex=0]').attr('aria-expanded', self._isExpanded());



        });
    },
    _setupKeyboardEvents: function () {
        $('.expand-block__clickable-row', this.element).keypress(function (e) {
            // Enter key == 13
            e.preventDefault()
            if (e.which == 13) {
                $(this).trigger('click');
            }
        });
    },
    _isExpanded: function () {
        var isExpanded = this.element.hasClass('expand-block--open');
        return isExpanded;
    }
});


