import { getGuid } from "../typescript/Vgr.Util.ts";
import $ from "jquery";
import * as L from "leaflet";
import "leaflet.markercluster";

/*
Example using Sweden Lantmäteriet Topografisk Webbkarta
https://opendata.lantmateriet.se/#apis
*/
$(document).ready(function () {
  var mapContainer = $(".vgr-search-result-map");
  if (mapContainer.length > 0) {
    /*** INSERT YOUR LANTMÄTERIET API TOKEN BELOW ***/
    var token = "fb3c80432560905ae9be32acbc5bc065";

    var crs = new L.Proj.CRS(
        "EPSG:3006",
        "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
        {
          resolutions: [4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8],
          origin: [-1200000.0, 8500000.0],
          bounds: L.bounds([-1200000.0, 8500000.0], [4305696.0, 2994304.0]),
        }
      ),
      map = new L.Map("map", {
        crs: crs,
        continuousWorld: true,
      });

    new L.TileLayer(
      "https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/token/" +
        token +
        "/?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topowebb&STYLE=default&TILEMATRIXSET=3006&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=image%2Fpng",
      {
        maxZoom: 9,
        minZoom: 0,
        continuousWorld: true,
        attribution:
          '&copy; <a href="http://www.lantmateriet.se/en/">Lantmäteriet</a> Topografisk Webbkarta Visning, CCB',
      }
    ).addTo(map);

    var clinics = $(".vgr-search-list__item");

    var data = [];
    $.each(clinics, function (index, value) {
      var $self = $(this);
      var $openstatus = $self.find("span[data-open-status]")[0];
      var openStatus = $openstatus ? $openstatus.outerHTML : "";
      var clinic = {
        id: $self.data("id"),
        name: $self.data("name"),
        position: [$self.data("coordinates-x"), $self.data("coordinates-y")],
        address: $self.data("address"),
        phone: $self.data("phone"),
        openstatus: openStatus,
        url: $self.data("url"),
      };
      data.push(clinic);
    });

    var marker;
    var markers = L.markerClusterGroup();
    var markerIds = [];
    var markerArray = [];

    for (var i = 0; i < data.length; i++) {
      marker = L.marker(data[i].position, { id: data[i].id });

      //marker = L.marker(crs.unproject(L.point(data[i].position[1], data[i].position[0])));
      marker.bindPopup(GetInfoWindowHtml(data[i]));
      marker.options.alt = "Clickable marker " + data[i].name;
      marker.options.title = "Clickable marker " + data[i].name;
      markers.addLayer(marker);
      markerArray.push(marker);
      markerIds[data[i].id] = marker;
    }
    map.addLayer(markers);

    var group = new L.featureGroup(markerArray);

    //Prevent race condition
    //setTimeout(map.fitBounds(group.getBounds()), 0);
    map.fitBounds(group.getBounds());

    $(".maplink").click(function (e) {
      e.preventDefault();
      $("html,body").animate({
        scrollTop: $("#map").offset().top - 60,
      });
      var id = $(this).parent().parent().data("id");
      var target = markerIds[id];
      markers.zoomToShowLayer(target, function () {
        target.openPopup();
      });
    });

    // Utgick från den här dokumentation: http://melmo.github.io/accessibility/code/dist/leaflet.html
    map.on("popupopen", function (popup) {
      // shift focus to the popup when it opens
      $(popup.popup._container)
        .find(".vgr-search-list-infowindow")
        .attr("tabindex", "-1")
        .focus();

      // move the close button to the end of the popup content so screen readers reach it
      // after the main popup content, not before
      var close = $(popup.popup._container).find(".leaflet-popup-close-button");
      $(popup.popup._container).find(".leaflet-popup-close-button").remove();
      close.attr("title", "Close item");
      $(popup.popup._container).append(close);
    });

    // return focus to the icon we started from before opening the pop up
    map.on("popupclose", function (popup) {
      $(popup.popup._source._icon).focus();
    });

    function GetInfoWindowHtml(clinic) {
      var guid = getGuid();
      var html =
        '<div class="vgr-search-list-infowindow"><a href="' +
        clinic.url +
        '"><h3>' +
        clinic.name +
        "</h3></a>";
      if (clinic.address.length > 0) {
        var addressLabel = "address-label" + guid;
        var addressText = "address-link" + guid;
        html += "<div>";
        html +=
          '<span class="vgr-search-list-infowindow__text vgr-search-list-infowindow__text--label" id="' +
          addressLabel +
          '" > Adress: </span > ';
        html +=
          '<span class="vgr-search-list-infowindow__text" aria-labelledby="' +
          addressLabel +
          " " +
          addressText +
          '" id="' +
          addressText +
          '" > ' +
          clinic.address +
          "</span>";
        html += "</div>";
      }
      if (clinic.phone.length > 0) {
        var phoneLabel = "phone-label" + guid;
        var phoneLink = "phone-text" + guid;
        html += "<div>";
        html +=
          '<span id="' +
          phoneLabel +
          '"  class="vgr-search-list-infowindow__text vgr-search-list-infowindow__text--label" >Telefon: </span>';
        html +=
          '<span class="vgr-search-list-infowindow__text"><a aria-labelledby="' +
          phoneLabel +
          " " +
          phoneLink +
          '" id="' +
          phoneLink +
          '" href="tel:' +
          clinic.phone +
          '">' +
          clinic.phone +
          "</a></span>";
        html += "</div>";
      }
      if (clinic.openstatus.length > 0) {
        html +=
          '<span class="vgr-search-list-infowindow__text vgr-search-list-infowindow__text--label" > Öppettider: </span > ';
        html += clinic.openstatus;
      }

      return html;
    }
  }

  //Only ask for position on page with searchresult-block
  if ($(".organizationsearchresultblock").length > 0) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        $("#CoordsX").val(position.coords.latitude);
        $("#CoordsY").val(position.coords.longitude);
      });
    } else {
      $(".relevance").hide();
    }
  }

  $(".clear-filters").click(function (e) {
    $(this).blur();
    e.preventDefault;
    $("#SelectedMunicipality").val("");
    $("#ServiceParameters").val($("#QueryCompletionServiceParameters").val());
    var $filterContainer = $(".vgr-search-filter");
    $filterContainer.find(".vgr-search__checkboxwrapper").each(function () {
      var $checkbox = $(this).children('input[type="checkbox"]');
      if ($checkbox.is(":checked")) {
        $(this).trigger("click");
      }
    });
  });

  $(document).keydown(function (e) {
    // ESCAPE key pressed
    if (e.keyCode === 27) {
      map.closePopup();
    }
  });
});
