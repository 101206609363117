﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.cookieInfo', {
    options: {

    },

    // Constructor
    _create: function () {
        this._initCookie();
        this._setupClickEvents();
    },
    _destroy: function () {
    },
    _setupClickEvents: function () {
        var self = this;
        $('#cookie-button', this.element).click(function (ev) {
            // set cookie
            $.cookie('cookieConsent', 'true', { expires: 365, path: '/' });

            // remove in ui
            $(self.element).slideUp('fast');

            ev.preventDefault();
        });
    },
    _initCookie: function() {
        if ($.cookie('cookieConsent') != 'true' && $.cookie('cookieConsent') != 'yes') {
            // no cookie, show consent box
            $(this.element).removeClass('hidden');
        }
    }
});