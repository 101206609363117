﻿import "../vendor/jquery-ui/jquery-ui";
import $ from "jquery";
import { getParameterByName } from "./../typescript/Vgr.Util";
import { Toggle } from "../../js/typescript/Vgr.Ui";

$.widget("vgr.jobSearch", {
  options: {},

  _init: function () {
    this._collapseSearchResult();

    this._sortOptionChanged();

    this._showResetButton();

    this._filterOptionReset();

    // Initiate municipality dropdown
    var closeOnOutsideClick = true;
    new Toggle(
      $(".dropdownListForCitys__toogle"),
      null,
      null,
      null,
      null,
      closeOnOutsideClick
    );
  },

  _filterOptionReset: function () {
    var self = this;
    $(".reset").click(function () {
      // Reload the page without any filters
      var url = window.location.href;
      if (url.indexOf("?") > 0) {
        url = url.substring(0, url.indexOf("?"));
      }
      var searchQuery = getParameterByName("q");
      window.location.replace(url + "?q=" + searchQuery + "&IsPost=true");
    });
  },
  _showResetButton: function () {
    $(".reset").removeClass("hidden");
  },
  _collapseSearchResult: function () {
    var collapseButton = $("#job-listing-collapse-button");

    var self = this;
    collapseButton.click(function () {
      self._switchedCollapseMode(collapseButton);

      // Prevent button from reloading the page
      return false;
    });
  },
  _switchedCollapseMode: function (collapseButton) {
    var searchResults = $("#job-listing-search-results");

    // Replace css classes to switch between expanded and collapsed mode
    searchResults.toggleClass("expanded");
    searchResults.toggleClass("collapsed");

    // Change class on button to switch icon
    var collapseButtonWrapper = $(".collapse-button-wrapper");
    collapseButtonWrapper.toggleClass("expanded");
    collapseButtonWrapper.toggleClass("collapsed");

    // Change text depending on collapsed state
    if (searchResults.hasClass("collapsed")) {
      collapseButton.text("visa träffar expanderade");

      // Save to cookie
      $.cookie("joblisting-collapsedMode", "collapsed", {
        expires: 365,
        path: "/",
      });
    } else {
      //window.history.pushState({ "html": $("html").html(), "pageTitle": document.title }, "", document.location + "&collapsed=true");
      collapseButton.text("visa träffar minimerade");

      // Save to cookie
      $.cookie("joblisting-collapsedMode", "expanded", {
        expires: 365,
        path: "/",
      });
    }
  },
  _sortOptionChanged: function () {
    // Resubmit the form if the sort option is selected
    $(".sort select", this.element).change(function () {
      var $form = $("#job-search-form");
      if ($form.data("is-post") === "True") {
        $form.submit();
      }
    });
  },
});

var $jobListingBlock = $("#job-listing");
if ($jobListingBlock.length > 0) {
  // Execute widget
  $jobListingBlock.jobSearch();
}
