﻿import "../vendor/jquery-ui/jquery-ui";
import $ from "jquery";
import { placeCursorAtEndOf, endsWith } from "./../typescript/Vgr.Util";

$.widget("customer.bestallarIdQueryCompletion", {
  options: {},

  // Constructor
  _create: function () {
    this._initQueryCompletion();
    this._handleClickEvent();
  },
  _destroy: function () {},
  _initQueryCompletion: function () {
    var searchField = $("#BestalladIdSearchQuery");
    var searchButton = $("#BestalladIdSearchButton");
    var resultContainer = $(".autocomplete-result");

    placeCursorAtEndOf(searchField);

    var urlRightPart = location.pathname;
    if (!endsWith(urlRightPart, "/")) {
      urlRightPart += "/";
    }

    var urlPath = "/api/QueryCompetions/BestallarIdQueryCompletion";

    this._addCloseResultContainerListener(
      searchField,
      searchButton,
      resultContainer
    );

    var self = this;
    searchField.autocomplete({
      minLength: 1,
      delay: 300,
      source: function (request) {
        $.ajax({
          dataType: "json",
          url: urlPath,
          type: "Get",
          data: { q: searchField.val() },
          success: function (data) {
            if (data === "") return;
            var jsObject = JSON.parse(data);
            if (jsObject.suggestions.length === 0) {
              resultContainer.addClass("hidden");
              return;
            }

            // Show the suggestions
            resultContainer.html("");
            resultContainer.removeClass("hidden");

            self._addSearchQueries(
              jsObject.suggestions,
              resultContainer,
              searchField
            );
          },
        });
      },
      select: function (suggestion) {
        console.log(
          "You selected: " + suggestion.value + ", " + suggestion.data
        );
      },
      focus: function (event, ui) {
        console.log("focused: " + ui.item.label);
        // or $('#autocomplete-input').val(ui.item.label);

        // Prevent the default focus behavior.
        event.preventDefault();
        // or return false;
      },
    });
  },
  _handleClickEvent: function () {
    var self = this;
    // Handles when a visitor clicks a query completion
    $(document).on("click", ".autocomplete-result > ul > li", function () {
      // we need to use "on" function since the suggestions are created after the dom is loaded
      self._doSearch(this);
      return false;
    });

    $(document).on("keyup", ".autocomplete-result li", function (e) {
      var keyCode = e.keyCode || e.which;

      if (keyCode == 13) {
        // Enter
        e.preventDefault();
        self._doSearch(e.target);
      }
    });
  },
  _addSearchQueries: function (queries, container, searchField) {
    //IS_IPAD = navigator.userAgent.match(/iPad/i) != null;
    //IS_IPHONE = navigator.userAgent.match(/iPhone/i) != null || (navigator.userAgent.match(/iPod/i) != null);

    if (queries.length < 1) return;

    var ul = container.append("<ul></ul>").find("ul");
    var template = $('div[data-id="template"]');
    var self = this;

    $.each(queries, function (i) {
      var label_hl = "",
        vgrid = "";

      if (queries[i].label_hl) label_hl = queries[i].label_hl;

      if (queries[i].vgrid) vgrid = queries[i].vgrid;

      var vgridElement = $(template).find(".vgr-quick-search__id").clone(); // Clone the element because it exists inside the headline.
      $(template)
        .find(".vgr-quick-search__headline")
        .html($.parseHTML(label_hl));
      $(template)
        .find(".vgr-quick-search__headline")
        .append(vgridElement.html($.parseHTML(vgrid))); // Append the clone

      //var bestalladId = self._getBestallarIds(queries[i].bestallar_id_benamning);

      self._addSuggestionNumbersOrHide(
        template,
        queries[i].bestallar_id,
        ".bNumber"
      );
      self._addSuggestionNumbersOrHide(
        template,
        queries[i].kiv_ansvarsnummer,
        ".aNumber"
      );
      self._addSuggestionNumbersOrHide(
        template,
        queries[i].i_number,
        ".iNumber"
      );

      ul.append(
        '<li tabindex="0" aria-selected="false" role="link" data-query="' +
          queries[i].query +
          '">' +
          template.html() +
          "</li>"
      );

      //var li = $("<li/>").appendTo(ulList);
      //var h3 = $("<h3>").appendTo(li);
      //var aaa = $("<a>", {
      //    html: queries[i].label_hl,
      //    href: url
      //}).appendTo(h3);
      //if (IS_IPAD || IS_IPHONE) {
      //    aaa.on('click touchend', function () {
      //        var link = $(this).attr('href');
      //        window.open(link, '_self');
      //        return false;
      //    });
      //}
    });

    // If there's 6 or more items we'll also add a link that executes a normal search.
    // Some user thougt that the query completion contained all search hits and this should solve that problem.
    if (queries.length >= 6) {
      ul.append(
        '<li tabindex="0" aria-selected="false" role="link" data-query="q=' +
          searchField.val() +
          '" class="vgr-quick-search__inline-list-show-all">Klicka här för att visa alla sökresutat</li>'
      );
    }
  },
  // NOTE: We currently don't take cirkulationsordning into consideration
  // bestallar_id_benamning is a string split into three fields, separeated by a "$" sign.
  //_getBestallarIds: function (unparsedBestallarIdBenamning) {
  //    var commaSeparatedBestallarIds = '';

  //    if (unparsedBestallarIdBenamning) {
  //        // Multiple id's are separeated by a comma
  //        var allBestallarIdBenamning = unparsedBestallarIdBenamning.split(',');

  //        $.each(allBestallarIdBenamning, function (i, value) {
  //            var split = value.split('$');

  //            if (split.length == 3) {
  //                // The last fields tells us what this id is for. All bestallar-id's ends with a 1. (Other fields serves a different purpose and we dont want them in the query completion)
  //                if (split[2] == '1') {
  //                    // The first field contains the id.
  //                    commaSeparatedBestallarIds += split[0] + ',';
  //                }
  //            }
  //        });
  //    }
  //    // Remove trailing comma
  //    commaSeparatedBestallarIds = commaSeparatedBestallarIds.replace(/,\s*$/, "");
  //    return commaSeparatedBestallarIds;
  //},
  _addSuggestionNumbersOrHide: function (
    template,
    suggestionData,
    appendToSelector
  ) {
    if (suggestionData && suggestionData != "") {
      // If there's more than one item
      if (suggestionData.split(",").length > 1) {
        // Then show a text insted that tells the user to click this suggestion to show all items
        var clickToShowAllHtml =
          '<span class="bestallar-id__show-more">' +
          "Flera alternativ tillgängliga" +
          "</span>" +
          '<svg class="vgr-quick-search__inline-list-icon bestallar-id__expand-close-icon" fill="#A9A9A9" height="15" viewBox="0 0 24 24" width="15" xmlns="http://www.w3.org/2000/svg">' +
          '<path d="M0 0h24v24H0z" fill="none" />' +
          '<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />' +
          "</svg>";
        suggestionData = clickToShowAllHtml;
      }

      // Show suggestions
      $(template).find(appendToSelector).html(suggestionData);
      $(template)
        .find(appendToSelector)
        .closest(".vgr-quick-search__inline-list-item")
        .removeClass("hidden");
    }
    // Hide suggestions
    else
      $(template)
        .find(appendToSelector)
        .closest(".vgr-quick-search__inline-list-item")
        .addClass("hidden");
  },
  _addCloseResultContainerListener: function (
    searchField,
    searchButton,
    resultContainer
  ) {
    this._closeResultContainerMousedown(
      searchField,
      searchButton,
      resultContainer
    );
    this._closeResultContainerFocusin(
      searchField,
      searchButton,
      resultContainer
    );
  },
  _closeResultContainerMousedown: function (
    searchField,
    searchButton,
    resultContainer
  ) {
    var self = this;
    $("body").click(function (e) {
      if (self._shouldHideSuggestions(e.target, searchField, resultContainer))
        resultContainer.addClass("hidden");
    });
  },
  _closeResultContainerFocusin: function (
    searchField,
    searchButton,
    resultContainer
  ) {
    var self = this;
    $("body").keyup(function (e) {
      var keyCode = e.keyCode || e.which;

      if (keyCode == 9) {
        // Tab

        if (
          self._shouldHideSuggestions(e.target, searchField, resultContainer)
        ) {
          resultContainer.addClass("hidden");
        }
      } else if (keyCode == 27) {
        // Esc
        resultContainer.addClass("hidden");
      }
    });
  },
  _shouldHideSuggestions: function (target, searchField, resultContainer) {
    // Look upward to find the query completion container
    var closestResultContainer = $(target).closest(
      "#" + resultContainer.attr("id")
    );
    var suggestionIsFocused =
      closestResultContainer && closestResultContainer.length > 0;

    // If the search input field is selected we still want to show the query completion
    if (
      !resultContainer.hasClass("hidden") &&
      !suggestionIsFocused &&
      target.id != searchField.attr("id")
    ) {
      return true;
    }
    return false;
  },
  _doSearch: function (selectedSuggestion) {
    if (!selectedSuggestion) return;

    var resultPageUrl = selectedSuggestion.closest("form").getAttribute("data-result-page-url");
    var query = $(selectedSuggestion).data("query"); // Get the clicked suggestion
    window.location.href = resultPageUrl + "?" + query; // Show search result page
  },
});
