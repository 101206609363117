﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.search', {
    options: {
        button: null
    },

    // Constructor
    _create: function () {
        this._setupClickEvents();
    },
    _destroy: function () {
    },
    _setupClickEvents: function () {
        var self = this;
        if (this.options.button) {
            $(this.options.button).click(function(event) {
                $(self.element).toggleClass('search--show');
                event.preventDefault();
            });
        }
    }
});