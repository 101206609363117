﻿import $ from "jquery";

$(function () {
    var childFilters = [];
    
    var helper = {
        existsByProperty: function (arr, prop, find) {
            for (var k in arr) {
                var item = arr[k];
                if (item[prop] && item[prop] == find) {
                    return true;
                }
            }
            return false;
        }
    };

    var hashManager = {
        reFilters: new RegExp('f=[0-9,]*[\|]{1}'),
        reSorting: new RegExp('s=[a-z]*[\|]{1}'),
        // Uppdaterar url hashen
        updateHash: function (state) {
            var self = this;
            var currentHash = location.hash;

            var matchAndReplace = function (re, newValue) {
                if (re.test(currentHash)) {
                    // replace
                    currentHash = currentHash.replace(re, newValue);
                }
                else {
                    // add new
                    currentHash += newValue;
                }
            }

            if (state.filters) {
                var newFilter = 'f=' + state.filters.toString() + '|';
                matchAndReplace(self.reFilters, newFilter);
            }
            if (state.sort) {
                var newSorting = 's=' + state.sort + '|';
                matchAndReplace(self.reSorting, newSorting);
            }
            location.hash = currentHash;
        },
        getSorting: function () {
            var self = this;
            var currentHash = location.hash ? location.hash : '';
            var res = self.reSorting.exec(currentHash);
            res = res ? res.toString() : '';
            return res.toString().replace('s=', '').replace('|', '');
        },
        getFilters: function () {
            var self = this;
            var currentHash = location.hash ? location.hash : '';
            var res = self.reFilters.exec(currentHash);
            res = res ? res.toString() : '';
            var arrFilters = [];
            if (res) {
                arrFilters = res.toString().replace('f=', '').replace('|', '').split(',');
            }
            return arrFilters;
        }
    };

    var FilteringComponent = function (settings) {
        this.applySorting = function (prop, asAsc, onSorting) {
            // ...sort() is an defult function in js: Array.prototype.sort()
            // Here we create our own version of it. It will also return the sorted array.
            settings.items.sort(function (a, b) { 
                if (a[prop] > b[prop]) {
                    return asAsc ? -1 : 1;
                }
                else if (a[prop] < b[prop]) {
                    return asAsc ? 1 : -1;
                }
                return 0;
            });
            if (onSorting) {
                onSorting(settings.items);
            }
        }

        this.applyFiltering = function (filteredItems) {

            for (var j = 0; j < settings.items.length; j++) {
                // Reset all items to be hidden
                settings.items[j].isVisible = false;
                for (var i = 0; i < filteredItems.length; i++) {

                    if (filteredItems[i].id === settings.items[j].id) {
                        settings.items[j].isVisible = true;
                        break;
                    }
                }
            }

            // Show or hide filter results
            if (settings.onResult) {
                settings.onResult(settings.items);
            }
        }

        // Why we need two different arrays of filters:
        // First of, the filters can either be radio buttons or checkboxes.
        // 
        // If the filter exists of checkboxes then multiple items of each group can be selected.
        // Groups usually exists of main filters and child filters.
        // Each group should be treated as an OR selector.
        // So we can select multiple filter per group and if the result item has any of the active filters it should be display.
        //
        // Between each group we have an AND selector. So selected filters form group one need to be true and the same for group two.
        this.getItemsWithFilters = function (items, activeFilters, activeChildFilters) {
            var filteredItems = [];
            var hasValue = function (values, find) {
                // NOTE: lookahead and lookbehind
                // Matchar filtret på resultatets egenskaper
                var re = new RegExp('(?:(,|^))' + find + '(?=,|$)');
                return re.test(values);
            }

            // Checks if any of the active filters exists as a property on the result item
            var hasAnyFilter = function (resultItem, filters) {
                var itemContainsFilter = false;
                for (var i in filters) {

                    var filter = filters[i];

                    if (hasValue(resultItem.properties, filter)) { // Har filterresultatet med det aktiva filtret i sina properties betyder det att den har en koppling till filtret och då ska resultatet visas
                        itemContainsFilter = true;
                        break;
                    }
                }
                return itemContainsFilter;
            }

            // loopa igenom en lista med alla filter-resultat
            for (var i = 0; i < items.length; i++) {
                var resultItem = items[i];

                var add = false;
                // Finns det några aktiva filter? Annars ska alla resultat visas
                if (activeFilters.length < 1) {
                    add = true;
                } else {
                    // If no child filters are selected or the child filter exits in the result item
                    var childFilterMatch = activeChildFilters.length < 1 || hasAnyFilter(resultItem, activeChildFilters);
                    // Should be visible if any main filter match together with any child filter
                    add = hasAnyFilter(resultItem, activeFilters) && childFilterMatch;
                }

                if (add) {
                    filteredItems.push(resultItem);
            }
            }
            return filteredItems;
        }
    }

    var $window = $(window),
        timeout = false,

        // Denna funktionen visar puffar.
        // "count" parametern är hur många puffar som ska visas. Den anpassas beroende på storlekten av webbläsarens fönster
        displayFeatured = function (counts) {
            // "featured" klassen visar puffarna
            var featuredClass = 'featured';
            $('.group-item').each(function () {
                var $this = $(this),
                    $btn = $this.find('.btn-all');

                $this.find('.result-item.featured').removeClass(featuredClass);
                // Filterresultat med klassen "included" ska visas.
                // Lägger på klasen på dem första X resultaten
                $this.find('.result-item.included:lt(' + counts + ')').addClass(featuredClass);

                // Visa bara "visa alla" knappen om det finns fler sökresultat än dem som visas om featured
                if ($this.find('.result-item.included').length <= counts) {
                    $btn.hide();
                } else {
                    $btn.show();
                }
                var equal = $this.find('.result-item.equalHM');
                $(equal).each(function () {
                    $(this).removeClass("equalHM");
                    $(this).addClass("FilterEqualItems");
                });
            });


        },
        setFeatured = function () {
            var width = $window.width(),
                count;

            if (width < 768) {
                // NOTE: Mobile view, display one item.
                count = 1;
            }
            else if (width < 992) {
                // NOTE: Tablet view, display two items.
                count = 2;
            } else {
                // NOTE: Desktop view, display three items.
                count = 3;
            }

            displayFeatured(count);

            timeout = !timeout;
        };

    // "items" är en lista av alla filterresultat. Den är skapad med ett antal properties.
    // Funktionen läggar till "included" klassen på filterresultat med träffar och sätter hur många träffar man har fått
    var filterResult = function (resultItems) {

        setVisibleStateFor(resultItems);

        setBadgeCounter();

        setFeatured();
    }

    var setVisibleStateFor = function (resultItems) {
        for (var i in resultItems) {
            var item = resultItems[i];
            var id = item.id;
            var isVisible = item.isVisible;
            var $item = $('[data-item-id="' + id + '"]');
            // Lägg på "included" klassen som senare används för att leta reda på resultat som ska visas.
            // Vilka som får klassen är baserat på isVisible som sätts tidigare
            isVisible ? $item.addClass('included') : $item.removeClass('included');
        }
    };

    var setBadgeCounter = function () {
        $('[data-group-id]').each(function () {
            var visibleItemsCount = $('[data-item-id].included', this).length;
            var groupHasItems = $('[data-item-id]', this).length != $('[data-item-id]:not(.included)', this).length;
            // Sets active class to result group if it has any active items
            groupHasItems ? $(this).addClass('active') : $(this).removeClass('active');
            // Sets the badge with the number of filterd items
            $('.badge', this).text(visibleItemsCount);
        });
    };


    // NOTE: reorder items inside their groups
    var sortingResult = function (items) {
        // Iterate each result group
        $('[data-group-id]').each(function () {
            var reorderedItems = [];

            for (var k in items) {
                var item = items[k];
                // Get html of the result item. We select each of the result items in the sort order.
                var $item = $('[data-item-id="' + item.id + '"]', this);
                if ($item) {
                    reorderedItems.push($item);
                }
            }

            var $items = $('[data-items]', this);
            // Removes all result items form the DOM
            $items.empty();

            for (var k in reorderedItems) {
                // Add reordered items to the DOM again
                $items.append(reorderedItems[k]);
            }
        });

        setFeatured();
    }
    //$('.equalHM').each(function () {
    //    $(this).removeClass("equalHM");
    //});

    // Iterates array of all child filters and checks parameter-element exits in the array. 
    //var isChildFilter = function (elem) {

    //    var isChildFilter = false;
    //    var id = $(elem).attr('data-filter-value');

    //    $(childFilters).each(function () {
    //        if (id === $(this).attr('data-filter-value')) {
    //            isChildFilter = true;
    //            return false; // This will only break the loop
    //        }
    //    });
    //    return isChildFilter;
    //}


    var getActiveFiltersIn = function (filterFieldset) {
        var activeFilters = [];

        // NOTE: Changed so Huvudkategori filters also applies to the Filtering
        // Alla filter med data-attributet "data-filter-active" är aktiva under filterkategorin
        $(filterFieldset).find('[data-filter-value][data-filter-active][data-filter-hidegroupoffilters-id=""]').each(function () {

            if ($(this).attr('data-filter-empty') === 'False') {
                activeFilters.push($(this).attr('data-filter-value'));
            }
        });

        return activeFilters;
    };

    var getItems = function () {
        var items = [];
        $('[data-item-id]').each(function () {
            var $item = $(this);

            // NOTE: Adds an item one time, they might exists multiple times on
            // the page
            var id = $item.attr('data-item-id');
            if (!helper.existsByProperty(items, 'id', id)) {
                var linkItem = {
                    'id': $item.attr('data-item-id'),
                    'date': $item.attr('data-item-date'), // NOTE: for sorting
                    'heading': $('h3.block__heading', $item).text(), // NOTE: for sorting
                    'properties': $item.attr('data-item-properties') // NOTE: for filtering
                };
                items.push(linkItem);
            }
        });
        return items;
    };

    // NOTE: setup filter handling
    $('[data-filter-id]').each(function () {
        var self = this;
        var filterType = $(self).attr('data-filter-type');
        var $filters = $('[data-filter-value]', self);

        // This event is called when a filter is clicked or a dropdown option is selected
        $filters.change(function () {
            var isCheckbox = filterType.toLowerCase().indexOf('multi') > -1;
            var hasChildren = $(this).attr('data-filter-children') != '';
            var hasHideGroupOfFilters = $(this).attr('data-filter-hidegroupoffilters-id') != '';
            var affectFiltersOnly = hasChildren || hasHideGroupOfFilters;

            var isEmpty = $(this).attr('data-filter-empty') === 'True';

            if (isCheckbox) {
                var isChecked = $(this).prop('checked');
                if (isEmpty && isChecked) {
                    // Remove all checkboxes except for the "All" button
                    $('[data-filter-value]:not([data-filter-empty="True"])', self).removeAttr('data-filter-active').removeAttr('checked');

                    // Hide child filters
                    var fieldset = $(this).closest('[data-filter-id]');
                    hideActiveChildFiltersRelatedToFieldset(fieldset);

                }
                else {
                    if (isChecked) {
                        $(this).attr('data-filter-active', '');

                        // Uncheck the "All" button
                        if (!isEmpty) {
                            $('[data-filter-empty="True"]').prop('checked', false);
                        }
                    }
                    else {
                        $(this).removeAttr('data-filter-active');
                    }
                }
            }
            else {
                $filters.removeAttr('data-filter-active');
                $(this).attr('data-filter-active', '');
            }

            var description = $('[data-filter-description]', $(this).parent()).text();
            if (description) {
                // NOTE: Now I let the latest text stay until there are another
                // text available on the latest filter applied
                $('[data-filter-info-text]').text(description);
            }

            // NOTE: Should just affect the other filters
            if (affectFiltersOnly) {
                var setStateSpecficFilters = function (findByFilterId, show) {
                    // Hitta filtret med värde
                    var $filters = $('[data-filter-value="' + findByFilterId + '"]');
                    // Wrapper element till filtret
                    var $filterItemWrapper = $filters.closest('[data-filter-item]');

                    if (show) {
                        // Visa filternaternativ
                        $filterItemWrapper.show();
                    }
                    else {
                        $filterItemWrapper.hide();
                        $filters.val([]).removeAttr('selected');
                        $filters.removeAttr('data-filter-active');
                    }
                }

                var hideGroupOfFiltersState = function (args) {
                    var $parent;
                    // Visar fieldsetet som som tillhör gruppen
                    if (args.findByFilterId) {
                        $parent = $('[data-filter-value="' + args.findByFilterId + '"]').closest('[data-filter-id]');
                        $parent.show();
                    }

                    // NOTE: Means: Hide all, the complete filter
                    if (args.parentFilterId) {
                        $parent = $('[data-filter-id="' + args.parentFilterId + '"]');
                        $parent.hide();
                    }

                    // NOTE: Set this filters $parent, all its child is set to
                    // not visible/selected


                    var $filters = $parent.find('[data-filter-value]');

                    $filters.val([]).removeAttr('selected');
                    $filters.removeAttr('data-filter-active');
                    $filters.closest('[data-filter-item]').hide();
                }

                if (hasHideGroupOfFilters) {
                    var parentFilterId = $(this).attr('data-filter-hidegroupoffilters-id');
                    hideGroupOfFiltersState({ parentFilterId: parentFilterId });
                    $('[data-filter-id="' + parentFilterId + '"] [data-filter-value]').each(function () {
                        var filterId = $(this).attr('data-filter-value');
                        if (filterId) {
                            setStateSpecficFilters(filterId, true);
                        }
                    });
                }
                else if (hasChildren) {

                    if (isCheckbox) {
                        // This means that the button is selected
                        if ($(this).attr('data-filter-active') !== undefined) {
                            changeVisibillityOfChildFilterItems(this, true);
                            // The button is deselected
                        } else {
                            changeVisibillityOfChildFilterItems(this, false);
                        }
                    }
                        // We dont need to deselect any main filters when dealing with radio buttons
                    else {
                        // Hide child filters
                        var fieldset = $(this).closest('[data-filter-id]');
                        hideActiveChildFiltersRelatedToFieldset(fieldset);

                        changeVisibillityOfChildFilterItems(this, true);
                    }
                }
            }
                // If this filter has no child filters and this is a radio button, then all child filters should be hidden
            else if (!hasChildren && !isCheckbox) {
                // Hide child filters
                var fieldset = $(this).closest('[data-filter-id]');
                hideActiveChildFiltersRelatedToFieldset(fieldset);
            }

            applyFiltersAndUpdateHash();
        });
    });

    $('input[name="filter-sorting"]').change(function () {
        var sortValue = $(this).attr('data-sorting-value');
        filteringInstance.applySorting(sortValue, $(this).attr('data-sorting-asc') === 'true', sortingResult);
        hashManager.updateHash({ sort: sortValue });

        $(this).prop('checked', true);
    });

    // NOTE: Had to do like this, bec something in the VGR framework is
    // stopping me from using *.trigger with additional data object supplied?
    var toggleShowAll = function (jqObject, data) {
        var forceShowAll = (data && data.forceShowAll) ? true : false;
        var $group = $(jqObject).closest('.group-item');

        var isAllVis = forceShowAll ? false : $group.hasClass('all-visible');
        isAllVis ? $group.removeClass('all-visible') : $group.addClass('all-visible');

        $(jqObject).text(isAllVis ? $(jqObject).attr('data-text-expand') : $(jqObject).attr('data-text-collapse'));

        if (isAllVis) {
            $('.result-item', $group).removeClass('active');
        }
    };

    // NOTE: Had to do like this, bec something in the VGR framework is
    // stopping me from using *.trigger with additional data object supplied?
    $('[data-group-id] .btn-all').click(function (event, data) {
        toggleShowAll(this, null);
        return false;
    });

    // NOTE: click to make this toggle expanded state. NOTE: bec I reaorder
    // these items, we need to use delegate
    $('[data-items]').on('click', "[data-item-id] .wrap", function () {
        var $item = $(this).closest('.result-item');
        var isActive = $item.hasClass('active');

        //toggleShowAll($(this).closest('[data-group-id]').find('.btn-all'), { 'forceShowAll': true });

        //$('.result-item').removeClass('active');
        //if (!isActive) {
        //    $item.addClass('active');
        //}

        //return false;
    });

    $('[data-filter-info-toggle-btn]').click(function () {
        var $par = $(this).closest('.info-wrap');
        $par.hasClass('active') ? $par.removeClass('active') : $par.addClass('active');
        return false;
    });

    var applyFiltersAndUpdateHash = function () {

        // These items will be filtered
        var items = getItems();
        
        var allActiveMainFilters = [];
        var allActiveChildFilters = [];
        // Each fieldset should be filtered as a single group as it may have an OR selector between main- and child items in the group.
        // Then each fieldset has an AND selector between them.
        $('fieldset[data-filter-id]').not('[data-filter-contains-child-filters]').each(function () {

            var fieldset = this;
            var activeFilters = getActiveFiltersIn(fieldset);
            var activeChildFilters = [];

            // Get releated child filters            
            $(fieldset).find('[data-filter-children][data-filter-empty=False]').each(function () {

                // Related child filters has their parent in this fieldset but exists in another fieldset.
                var relatedChildFilters = $(this).attr('data-filter-children').split(',');
                if (relatedChildFilters.length > 0) {
                    $(relatedChildFilters).each(function () {
                        var id = this;
                        // Select the child filter by id
                        var childFilter = $('[data-filter-value="' + id + '"][data-filter-empty=False]');

                        // If the filter is active
                        if ($(childFilter).attr('data-filter-active') !== undefined && $(childFilter).attr('data-filter-hidegroupoffilters-id') == "") {
                            // If the filter hasn't already been added
                            if (activeChildFilters.indexOf(id.toString()) < 0) {
                                activeChildFilters.push(id.toString());
                            }
                        }
                    });
                }
            });

            // Now we can filter the do an OR operator between main- and child filters.
            // We reuse the same list of items for the next iteration as this will create the AND operatior between fieldsets
            items = filteringInstance.getItemsWithFilters(items, activeFilters, activeChildFilters);

            $.merge(allActiveMainFilters, activeFilters);
            $.merge(allActiveChildFilters, activeChildFilters);
        });

        filteringInstance.applyFiltering(items);

        var allActiveFilters = $.merge(allActiveMainFilters, allActiveChildFilters);
        hashManager.updateHash({ filters: allActiveFilters });
    };

    var getChildFilters = function () {
        var $allFilters = $('[data-filter-item] input');

        var allChildFilterIds = [];
        // Exlude all items that does not have child filters
        $allFilters.filter($('[data-filter-children!=""]')).each(function () {
            // Select the childrens id's and add the id's to an array
            $.merge(allChildFilterIds, $(this).attr('data-filter-children').split(','));
        });
        // Remove duplicates
        allChildFilterIds = $.unique(allChildFilterIds);

        var allChildFilters = [];
        $(allChildFilterIds).each(function () {
            var id = this;
            // Get the element from id
            allChildFilters.push($('[data-filter-value="' + id + '"]'));
        });
        return allChildFilters;
    };

    var setDefaultsForAllChildFilterFieldsets = function () {
        $(childFilters).each(function () {
            var $fieldset = $(this).closest('[data-filter-id]');
            // Let us know that this filedset is for child filters
            $fieldset.attr('data-filter-contains-child-filters', '');
            // The default state of child filters is that they should be hidden if no filter is selected
            $fieldset.hide();
        });
    };

    // Visar fieldsetet för och filter för sub-filter för föräldern
    // isSingleSelect = radio button
    var changeVisibillityOfChildFilterItems = function (clickedInput, show) {
        // Get child filters of this item
        var activeChildFiltersIds = $(clickedInput).attr('data-filter-children').split(',');

        if (activeChildFiltersIds.length > 0 && activeChildFiltersIds[0] != '') {
            $(activeChildFiltersIds).each(function () {

                var id = this;

                var filterItem = ($('[data-filter-value="' + id + '"]')).closest('[data-filter-item]');
                if (filterItem.length < 1) {
                    return true; // Continue the loop
                }

                var fieldset = $(filterItem).closest('[data-filter-id]');

                if (show) {
                    showChildFilterItemsGroup(filterItem, fieldset);
                } else {
                    hideChildFilterItemsGroup(id, filterItem, fieldset);
                }
            });
        }
    };

    var hideChildFilterItemsGroup = function (id, filterItem, fieldset) {
        var hasActiveParent = false;
        // Iterate all active filters
        $('[data-filter-active]').each(function () {
            var childrenOfActiveItems = $(this).attr('data-filter-children').split(',');

            // Is the child filter we are looking to hide in another active filter?
            if (childrenOfActiveItems.indexOf(id.toString()) >= 0) {
                hasActiveParent = true;
                return false; // This will only break the loop
            }
        });

        // If another active filter has this as a child filter, it should not be hidden
        if (hasActiveParent) {
            return;
        }

        resetChildFiltersAndFieldset(filterItem, fieldset);

    };

    var resetChildFiltersAndFieldset = function (filterItem, fieldset) {
        // Hide filter buttons
        var input = filterItem.children('input');
        input.val([]).removeAttr('selected');
        input.removeAttr('data-filter-active');
        filterItem.hide();

        var $filters = $(fieldset).find('[data-filter-item]');
        // If the filedset contains no visible filters
        var visibleItemsInFieldset = $filters.filter(':visible');
        // Hide the fieldset
        if (fieldset.is(":visible") && visibleItemsInFieldset.length < 1) {
            fieldset.hide();
        }
    };

    var showChildFilterItemsGroup = function (filterItem, fieldset) {

        // Shows the fieldset
        if (!fieldset.is(":visible")) {
            fieldset.show();

            var $filters = $(fieldset).find('[data-filter-value]');

            // Hide all filters form the start
            $filters.val([]).removeAttr('selected');
            $filters.removeAttr('data-filter-active');
            $filters.closest('[data-filter-item]').hide();
        }

        // Show filter buttons
        if (!filterItem.is(":visible")) {
            filterItem.show();
        }
    };

    // Hide active child filters that has a releation to the fieldset
    var hideActiveChildFiltersRelatedToFieldset = function (fieldset) {

        $(fieldset).find($('[data-filter-value]')).each(function () {
            // Hide child filters and thier remove active state
            changeVisibillityOfChildFilterItems(this, false);
        });
    };

    var resetAllSelectedFilters = function () {
        // "active" data attribute defines which filters are selcted, so we want to remove all "active" attributes
        $('[data-filter-active]').each(function () {
            this.removeAttribute('data-filter-active');
            $(this).prop('checked', false);

            // Hide child filters
            var fieldset = $(this).closest('[data-filter-id]');
            hideActiveChildFiltersRelatedToFieldset(fieldset);
        });

        // Check each "All" buttons
        $('[data-filter-empty="True"]').each(function () {
            $(this).prop('checked', true);
        });

        // Reset all dropdown values
        $('button.dropdown-toggle').each(function () {
            $(this).html('Välj <span class="caret"></span>');
        });
    };

    $('#reset-button').click(function () {

        resetAllSelectedFilters();

        applyFiltersAndUpdateHash();
    });

    $('#toggle-filteritems').click(function () {

        var openFilterText = $("#filter-show")[0];
        var closeFilterText = $("#filter-hide")[0];

        if ($(openFilterText).hasClass("hidden")) {
            $(openFilterText).removeClass("hidden");
            $(closeFilterText).addClass("hidden");
        } else {
            $(openFilterText).addClass("hidden");
            $(closeFilterText).removeClass("hidden");
        }
    });

    // NOTE: init
    $('[data-group-id]').removeClass('init').removeClass('all-visible');

    var filteringInstance = new FilteringComponent({ onResult: filterResult, items: getItems() });
    childFilters = getChildFilters();


    setDefaultsForAllChildFilterFieldsets();

    // NOTE: get possible hash-values and do settings
    var _isSorted = false;
    if (location.hash) {
        var sorting = hashManager.getSorting();
        var filters = hashManager.getFilters();
        if (sorting) {
            $('input[name="filter-sorting"][data-sorting-value="' + sorting + '"]').trigger('click');
            _isSorted = true;
        }
        if (filters && filters.length > 0) {
            for (var i in filters) {
                var filter = filters[i];
                if (filter) {
                    var $filter = $('[data-filter-value="' + filter + '"]');
                    if ($filter) {
                        // This is what filters the result items on the first page load
                        $filter.trigger('click'); // This will call the "$filters.change()" event
                    }
                }
            }
        }
    }

    // NOTE: if not selected by hash params, select first 
    if (!_isSorted) {
        $('input[name="filter-sorting"]').first().trigger('click');
    }

    // NOTE: this will go through all filters and if it exists any
    // [data-filter-hidegroupoffilters-id] and none of it's sibling's filters
    // is selected, this should be default selected
    $('[data-filter-value][data-filter-hidegroupoffilters-id!=""]').each(function () {
        var self = this;
        var $items_active = $(self).closest('fieldset').find('[data-filter-value][data-filter-active]');
        if ($items_active.length === 0) {
            $(self).trigger('click');
        }
    });

    $window.on('resize', function () {
        if (!timeout) {
            timeout = !timeout;

            setTimeout(setFeatured, 100);
        }
    });

    $(window).on('load', function () {
        setFeatured();
    });
});
