﻿import $ from "jquery";
import { endsWith } from "./typescript/Vgr.Util";

$.widget("customer.organizationQueryCompletion", {
  options: {
    source: "",
    header: "",
  },

  // Constructor
  _create: function () {
    this._initQueryCompletion();
  },
  _destroy: function () {},
  _initQueryCompletion: function () {
    var searchField = this.element;
    if (searchField.length <= 0) {
      return;
    }

    var resultContainer = $("#autocomplete-result");
    var searchButton = $("#SearchButton");
    var organizationName = searchField.data("organizationname");
    var searchQuery = $("#QueryCompletionString").val();
    var urlRightPart = location.pathname;

    if (!endsWith(urlRightPart, "/")) {
      urlRightPart += "/";
    }

    var endpoint = "OrganizationQueryCompletion";

    if (this.options.source === "episerver") {
      endpoint = "ProjectSearchQueryCompletion";
    }

    var urlPath = "/api/QueryCompetions/" + endpoint;

    this._addCloseResultContainerListenerOs(
      searchField,
      searchButton,
      resultContainer
    );

    if (!searchQuery) {
      // The object cannot be undefiend, and the letters will be added before it's send to the backend method.
      searchQuery = "q=";
    }

    var scope = "";
    var $scope = $("#SearchScope");
    if ($scope.length > 0) {
      scope = $scope.data("scope");
    }

    var self = this;
    searchField.autocomplete({
      minLength: 1,
      delay: 300,
      source: function (request) {
        $.ajax({
          dataType: "json",
          url: urlPath,
          type: "Get",
          data: {
            source: self.options.source,
            scope: scope,
            searchQuery: searchQuery.replace("q=", "q=" + request.term),
          },
          success: function (data) {
            if (data === "") return;
            /*var jsObject = JSON.parse(data);*/
            if (data.suggestions.length === 0) {
              resultContainer.addClass("hidden");
              return;
            }
            resultContainer.html("");
            resultContainer.css("width", searchField.css("width"));
            resultContainer.removeClass("hidden");

            self._addSearchQueries(data.suggestions, resultContainer);
          },
        });
      },
    });
  },
  _addSearchQueries: function (queries, container) {
    //IS_IPAD = navigator.userAgent.match(/iPad/i) != null;
    //IS_IPHONE =
    //  navigator.userAgent.match(/iPhone/i) != null ||
    //  navigator.userAgent.match(/iPod/i) != null;

    var form = $("#search-form");

    var ulList = $(container)
      .append("<ul id='searchquery-list' ></ul>")
      .find("#searchquery-list");
    var $header = $("<LH>", {
      html: "<h2>" + this.options.header + "<h2>",
    }).appendTo(ulList);
    $header.addClass("autocomplete-resultheader");
    if (queries.length < 1) return;
    var self = this;
    $.each(queries, function (i) {
      var url = "";
      if (self.options.source == "episerver") {
        // If the source is a webpage then the the suggestion is a link to an page
        url = queries[i].label;
      } else {
        //Remove previous q-parameter and add it again with query completion value
        url =
          $("#FullQueryCompletionResultString")
            .val()
            .replace(/&?q=([^&]$|[^&]*)/i, "") +
          "&q=" +
          queries[i].label;
      }

      var li = $("<li/>").appendTo(ulList);
      var h3 = $("<h3>").appendTo(li);
      var aaa = $("<a>", {
        html: queries[i].label_hl,
        href: url,
      }).appendTo(h3);
      //if (IS_IPAD || IS_IPHONE) {
      //  aaa.on("click touchend", function () {
      //    var link = $(this).attr("href");
      //    window.open(link, "_self");
      //    return false;
      //  });
      //}
    });
  },
  _addCloseResultContainerListenerOs: function (
    searchField,
    searchButton,
    resultContainer
  ) {
    this._closeResultContainerMousedownOs(resultContainer);
    this._closeResultContainerFocusinOs(
      searchField,
      searchButton,
      resultContainer
    );
    this._closeResultContainerEscOs(resultContainer);
  },
  _closeResultContainerMousedownOs: function (resultContainer) {
    $("body").mousedown(function (e) {
      if (
        e.target.offsetParent == null ||
        e.target.offsetParent.id !== resultContainer[0].id
      ) {
        resultContainer.addClass("hidden");
      }
    });
  },
  _closeResultContainerFocusinOs: function (
    searchField,
    searchButton,
    resultContainer
  ) {
    $("body").focusin(function (e) {
      var focused = document.activeElement.id;
      // offsetParent is undefiend in some versions of IE 11
      if (e.target.offsetParent) {
        if (
          focused !== searchField[0].id &&
          focused !== searchButton[0].id &&
          e.target.offsetParent.id !== resultContainer[0].id
        ) {
          resultContainer.addClass("hidden");
        }
      }
    });
  },
  _closeResultContainerEscOs: function (resultContainer) {
    $(document).on("keyup", function (evt) {
      if (evt.keyCode == 27) {
        resultContainer.addClass("hidden");
      }
    });
  },
});
