import * as $ from "jquery";


$.fn.extend({
      closest_descendent : function (filter: String): JQuery {
    var $found = $(),
        $currentSet = this; // Current place
    while ($currentSet.length) {
        $found = $currentSet.filter(filter);
        if ($found.length) break;  // At least one match: break loop
        // Get all children of the current set
        $currentSet = $currentSet.children();
    }
    return $found.first(); // Return first match of the collection
}    
});

export function isInViewport(element: Element): boolean {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

// Generates and returns a unique rfc4122 version 4 compliant GUID
export function getGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

// String.prototype.startsWith() with fallback for IE
export function stringStartsWith(sourceString, search, pos): boolean {
    
    if (!String.prototype.startsWith) {
        return sourceString.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    } else {
        return sourceString.startsWith(search, pos);
    }
}

// Custom endsWith function because String.prototype.endsWith() doesn't work in IE
export function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => (r + r + g + g + b + b));

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function forceRedrawOfElement(elem) {
    elem.style.display = 'none';
    elem.offsetHeight; // no need to store this anywhere, the reference is enough
    elem.style.display = '';
}

/**
 * detect IE
 * returns version of IE or 0, if browser is not Internet Explorer
 */
export function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return 0;
}

export function isFirefox() {
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    return isFirefox;
}

// https://stackoverflow.com/a/13348618
export function isChrome() {
    // please note, 
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
        // is Google Chrome on IOS
        return true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isIEedge === false
    ) {
        // is Google Chrome
        return true;
    } else {
        // not Google Chrome 
        return false;
    }
}

export function getCurrentBreakpoint() {

    // the viewport is at least 1560 pixels wide 
    if (window.matchMedia("(min-width: 1560px)").matches) { // Extra large
        return 1560;
    }
    // the viewport is less than 1560 pixels wide
    if (window.matchMedia("(min-width: 1024px)").matches) { // Large
        return 1024;
    }
    if (window.matchMedia("(min-width: 768px)").matches) { // Medium
        return 768;
    }
    if (window.matchMedia("(min-width: 640px)").matches) { // Small
        return 640;
    }
    if (window.matchMedia("(min-width: 320px)").matches) { // Tiny
        return 320;
    }

    // Not sure what should be returned as default...
    return window.innerWidth;
}

export function placeCursorAtEndOf(inputElement) {

    // Multiply by 2 to ensure the cursor always ends up at the end;
    // Opera sometimes sees a carriage return as 2 characters.
    var strLength = inputElement.val().length * 2;

    inputElement.focus();
    inputElement[0].setSelectionRange(strLength, strLength);
}

export function queryParamtersAsDictionary() {
    var queryString = window.location.search || '';
    var keyValPairs = [];
    var params = {};
    queryString = queryString.substr(1);

    if (queryString.length) {
        keyValPairs = queryString.split('&');

        for (var i = 0; i < keyValPairs.length; i++) {
            var key = keyValPairs[i].split('=')[0];
            if (!key.length) continue;
            key = key.toLowerCase();
            if (typeof params[key] === 'undefined')
                params[key] = [];
            params[key].push(keyValPairs[i].split('=')[1]);
        }
    }
    return params;
}

export function dictonaryToQueryString(dictionary, encode: boolean) {
    var str = [];
    for (var p in dictionary) {
        if(encode)
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dictionary[p]));
        else
            str.push(p + "=" + dictionary[p]);
    }
    return "?" + str.join("&");
}

//https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function trimEnd(myString, char) {

    if (myString.charAt(myString.length - 1) == char) {
        myString = myString.substr(0, myString.length - 1);
    }

    return myString;
}

// Groups an array by a property
export function groupBy(array, prop) {

    return array.reduce(function (groups, item) {
        var val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
    }, {});
}

export function truncateText(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

export function getFileExtension(filename) {
    var fileExtension = filename.split('.').pop();

    if (fileExtension)
        fileExtension = fileExtension.toLowerCase();

    return fileExtension;
}

// Checks if the file is a presentation, spreadsheet or document
export function fileIsWorkDocument(filename) {
    var ext = getFileExtension(filename);
    
    var isWorkDocument = ext == "key" || ext == "odp" || ext == "pps" || ext == "ppt" || ext == "pptx" || // Presentation file formats by file extension
        ext == "ods" || ext == "xlr" || ext == "xls" || ext == "xlsx" || // Spreadsheet file formats by file extension
        ext == "doc" || ext == "docx" || ext == "odt" || ext == "pdf" || ext == "rtf" || ext == "tex" || ext == "txt" || ext == "wks" || ext == "wps" || ext == "wpd"; // Word processor and text file formats by file extension
    
    return isWorkDocument;
}