﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.toggleDataActive', {
    options: {
        
    },
    _create: function() {
        this._setupClickEvents();
    },
    _setupClickEvents: function() {
        var self = this.element;
        self.click(function() {
            if (self.attr('data-active') === 'active') {
                self.removeAttr('data-active');
                self.attr('aria-expanded', 'false');
            } else {
                self.attr('data-active', 'active');
                self.attr('aria-expanded', 'true');
            }
        });
    }
});