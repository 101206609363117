﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.cascadingDropdownlist', {
    options: {

    },

    // Constructor
    _create: function () {
        this._setupDropdownlist();
    },
    _destroy: function () { },
    _setupDropdownlist: function () {
        var self = this;
        var $dropdowns = $(this.element);
        $.each($dropdowns, function () {
            var $dropdown = $(this);
            var $targetDropdown = $($dropdown.attr("data-target"));
            var targetListUrl = $dropdown.attr("data-url");
            var targetSelectedValue = $dropdown.attr("data-target-selected-value");
            var targetDefaultOptionText = $dropdown.attr("data-target-default-option-text");

            if ($(this).val() > 0) {
                self._bindData($dropdown.val(), $targetDropdown, targetListUrl, targetSelectedValue, targetDefaultOptionText);
            }

            $dropdown.click(function () {
                self._bindData($(this).val(), $targetDropdown, targetListUrl, targetSelectedValue, targetDefaultOptionText);
            });
        });
    },
    _bindData: function (id, $targetDropdown, targetListUrl, targetSelectedValue, targetDefaultOptionText) {
        $.ajax({
            url: targetListUrl,
            data: { id: id },
            cache: false,
            type: "GET",
            success: function (data) {
                var markup = "<option value='0'>" + targetDefaultOptionText + "</option>";

                $.each(data, function () {
                    if (this.Value === targetSelectedValue) {
                        markup += "<option value=" + this.Value + " selected>" + this.Text + "</option>";
                    } else {
                        markup += "<option value=" + this.Value + ">" + this.Text + "</option>";
                    }

                });

                $targetDropdown.html(markup).show();
            },
            error: function (reponse) {
                alert("error : " + reponse);
            }
        });
    }
});