import * as $ from "jquery";
import { getGuid } from "./Vgr.Util";
import { isFirefox } from "./Vgr.Util";

declare global {
    interface Window {
        Toggle: any;
    }
}
export interface IScreenReaderText {
    element: JQuery;
    showtext: string;
    hidetext: string;
}

export interface ICheckboxGroup {
    grouplabel: JQuery;
    groupcheckbox: JQuery;
    subcheckboxes: Array<JQuery>;
    groupIsChecked: boolean;
}

const checkboxstatus: { allchecked: string; nonechecked: string; mixed: string } = {
    allchecked: 'all-checked',
    nonechecked: 'none-checked',
    mixed: 'mixed'
}

export class MultiCheckbox {
    private checkboxGroups: Array<ICheckboxGroup>;
    private numCheckedBoxes: number;
    private buttonLabel: JQuery;

    constructor(element: JQuery, resetCheckboxes: boolean) {
        this.numCheckedBoxes = 0;
        this.buttonLabel = $('.vgr-multicheckbox__buttonlabel', element);
        this.checkboxGroups = new Array();
        $('[data-group]', element).each((i, e) => {
            var subcheckboxes = new Array();
            $('input[type=checkbox]', e).each((i, e) => {
                subcheckboxes.push($(e));
            });
            var guid = getGuid();
            var groupElement = $('[data-insertafter]', e);
            var grouplabelText = groupElement[0].innerHTML;

            // Optional
            var grouplabelName = groupElement.data('name');
            var grouplabelSelected = groupElement.data('selected');
            var grouplabelValue = groupElement.data('value');
            var grouplabelChecked = groupElement.data('checked');

            var projectsearchblock = $('.projectsearchblock');
            var Checked = grouplabelSelected && grouplabelSelected === "True" ? "data-checked" : "";

            var grouplabel = $('[data-insertafter]', e).after(`<label data-grouplabel id="${guid}">${grouplabelText}</label>`).next();
            $(`[id=${guid}]`, e).prepend(`<input type="checkbox" ${Checked} name="${grouplabelName}" value="${grouplabelValue}" />`).next();

            var groupcheckbox = $(`[id=${guid}]`, e).find('input[type=checkbox]');


            this.checkboxGroups.push({
                grouplabel: grouplabel,
                groupcheckbox: groupcheckbox,
                subcheckboxes: subcheckboxes,
                groupIsChecked: false
            });


        });

        if (resetCheckboxes) {
            this.uncheckAllCheckboxes();
        }
        // Leave the checkboxes ticked as they were
        else {

            this.checkboxGroups.forEach((group) => {
                // Check if the group should be selected
                this.subcheckClick(group);
            });
        }

        this.setInteractionEvents();

        $('[data-checked]').prop("checked", true);

    }

    private uncheckAllCheckboxes = () => {
        this.checkboxGroups.forEach((e) => {
            e.subcheckboxes.forEach((checkbox) => {
                checkbox.prop('checked', false);
            });
        });
    };

    private setInteractionEvents = () => {
        this.checkboxGroups.forEach((e) => {
            e.groupcheckbox.click(() => {
                this.checkAllSubboxes(e);
                this.flipCheckedState(e);
                this.countCheckedBoxes();
            });
            e.subcheckboxes.forEach((checkbox) => {
                checkbox.click(() => {
                    this.subcheckClick(e);
                });
            });
        });
    };

    private subcheckClick = (e: ICheckboxGroup) => {
        switch (this.subCheckboxesStatus(e)) {
            case checkboxstatus.allchecked:
                //check the groupbox
                e.groupcheckbox.prop('checked', true);
                e.groupIsChecked = true;
                break;
            case checkboxstatus.nonechecked:
                //uncheck the groupbox
                e.groupcheckbox.prop('checked', false);
                e.groupIsChecked = false;
                break;
            case checkboxstatus.mixed:
                //uncheck the groupbox
                e.groupcheckbox.prop('checked', false);
                e.groupIsChecked = false;
                break;
        }
        this.countCheckedBoxes();
    };

    private countCheckedBoxes = () => {
        this.numCheckedBoxes = 0;
        this.checkboxGroups.forEach((e) => {
            var checkedBoxes = e.subcheckboxes.filter(f => f.prop('checked') === true);
            this.numCheckedBoxes += checkedBoxes.length;
        });
        this.updateButtonLabel();
    }

    private updateButtonLabel = () => {
        this.buttonLabel.html(`${this.buttonLabel.data('chosenlabel')} (${this.numCheckedBoxes.toString()})`);
    }

    private subCheckboxesStatus = (e: ICheckboxGroup): string => {
        if (e.subcheckboxes.every(f => f.prop('checked') === true) && e.subcheckboxes.length > 0)
            return checkboxstatus.allchecked;
        if (e.subcheckboxes.every(f => f.prop('checked') === false))
            return checkboxstatus.nonechecked;
        return checkboxstatus.mixed;
    }

    private checkAllSubboxes = (e: ICheckboxGroup) => {
        e.subcheckboxes.forEach((checkbox) => {
            checkbox.prop('checked', !e.groupIsChecked);
        });
    }

    private flipCheckedState = (e: ICheckboxGroup) => {
        e.groupIsChecked = !e.groupIsChecked;
    }
}

export class Toggle {
    private tab: JQuery;
    private close: JQuery;
    private panel: JQuery;
    private element: JQuery;
    private focusElement: JQuery;
    private screenReaderText: IScreenReaderText;
    public guid: string;
    private isExpanded: boolean;
    private toggleFunction: Function;
    private closeOnOutsideClick: boolean;
    constructor(element: JQuery, tab?: JQuery, panel?: JQuery, focusElement?: JQuery, togglestyle?: string, closeOnOutsideClick?: boolean) {
        this.element = element;
        // Select the closest data-tab in case toggle buttons are nested.
        this.tab = tab ? tab : $(this.element).closest_descendent("[data-tab]:first");

        this.panel = panel ? panel : $('[data-panel]:first', this.element);
        this.close = $(this.panel).closest_descendent('.close');
        this.focusElement = focusElement ? focusElement : this.panel;
        const toggleStyle = togglestyle ? togglestyle : element.data('togglestyle');

        this.closeOnOutsideClick = closeOnOutsideClick;

        // Type of toggle style
        switch (toggleStyle) {
            case 'fade':
                this.toggleFunction = () => {
                    this.panel.fadeToggle('fast');
                };
                break;
            case 'slide':
                this.toggleFunction = () => {
                    this.panel.slideToggle();
                };
                break;
            case 'class':
                this.toggleFunction = () => {
                    this.panel.toggleClass('open');
                };
                break;
            case 'mobilemenu':
                this.toggleFunction = () => {
                    var panel = this.panel as any;
                    if (this.isExpanded) {
                        // Close the menu
                        panel.transition({ x: '110%' }, 200, () => {
                            panel.toggleClass('open');
                        });
                        var eventClose = document.createEvent('Event');
                        eventClose.initEvent('mobilemenu-close', true, true);
                        document.dispatchEvent(eventClose);
                    } else {
                        // Open the menu
                        panel.toggleClass('open');
                        panel.transition({ x: '0' }, 200);
                        var eventOpen = document.createEvent('Event');
                        eventOpen.initEvent('mobilemenu-open', true, true);
                        document.dispatchEvent(eventOpen);
                    }
                };
                break;
            default:
                this.toggleFunction = () => {

                    this.panel.toggle();

                };
        }

        this.isExpanded = element != undefined ? element.is('[data-startopen]') : false;

        const $sr = $('span[data-showtext]', this.tab);
        this.screenReaderText = {
            element: $sr,
            showtext: $sr.data('showtext'),
            hidetext: $sr.data('hidetext')
        }


        this.setAttributes();
        this.setInteractionEvents();
        this.setScreenReaderText();
    }

    private setAttributes = () => {
        var guid = getGuid();
        // Set a data attribute on the container element, used to indicate status of expansion - for CSS purposes

        // Set which element the 'tab element' opens, for screen readers
        this.tab.attr('aria-controls', guid);
        this.tab.attr('aria-expanded', this.isExpanded.toString());
        this.tab.attr('data-isexpanded', (this.isExpanded).toString());

        this.close.attr('aria-controls', guid);
        this.close.attr('aria-expanded', this.isExpanded.toString());
        this.close.attr('data-isexpanded', (this.isExpanded).toString());

        // Set the panels id to correspond to our guid
        this.panel.attr('id', guid);
        // Set the panels tabindex to -1, this will make it focusable via code - but not via keyboard tabbing
        this.panel.attr('tabindex', '-1');

        // Set the panels aria-expanded state
        this.panel.attr('data-isexpanded', this.isExpanded.toString());
    }

    private setScreenReaderText = () => {
        this.screenReaderText.element.text(this.isExpanded
            ? this.screenReaderText.hidetext
            : this.screenReaderText.showtext);
    }

    private setInteractionEvents = () => {

        var self = this;

        var enterKey = 13, spaceKey = 32;

        // Change the label to the checked radio button on page load
        $('.radiolist ul li input[type="radio"]').each(function (i, e: HTMLInputElement) {
            if ($(this).is(':checked')) {
                var titleOfSeletedItem = $("label[for='" + e.value + "']").text();
                $('.list-heading').text(titleOfSeletedItem);
                return false; // Break the loop as we have already set the text
            }
        });
        this.tab.add(this.close).click(() => {

            var self = this;
            if (this.closeOnOutsideClick) {
                // When a radio button is clicked
                $('.radiolist ul li input[type="radio"]').on('click', function (i, e: HTMLInputElement) {

                    var label = $("label[for='" + e.value + "']");

                    // Set the drop down label to the selected radio button
                    $('.list-heading').text(label.text());

                    // Hide the panel
                    self.toggleFunction();
                    self.flipExpandedState();
                    self.setScreenReaderText();

                    // Unbind click events
                    $('body').unbind('click');
                    $('.radiolist ul li input[type="radio"]').unbind('click');
                    $(document).unbind('keypress');
                });
            }

            this.toggleFunction();
            this.flipExpandedState();
            this.setScreenReaderText();
            if (this.isExpanded) {
                // 'Esc' keyup = 27
                $(document).on('keyup.escape', (e) => {
                    if (e.keyCode === 27) {
                        this.tab.trigger('click');
                    }
                });

                if (self.closeOnOutsideClick) {
                    // Handle keyboard navigation for radio button list with a checkbox at the top.
                    $(document).bind('keypress', function (e) {
                        if ((e.keyCode ? e.keyCode : e.which) === spaceKey) {
                            e.preventDefault();

                            if (document.activeElement) {// Check if this element is focused

                                // Check if the focused element is a child of the panel
                                var isInPanel = $(document.activeElement).parent(".radiolist") != undefined;

                                if (isInPanel) {
                                    // the checkbox is clicked and not the label
                                    if ($(document.activeElement).is(':checkbox') == true) {
                                        $(document.activeElement).click();
                                    } else {
                                        // Get the clicked label's for attribute
                                        var labelFor = $(document.activeElement).attr('for');
                                        var focusedInput = $('#' + $.escapeSelector(labelFor)); // Use escapeSelector() because the id may contain some invalid characthers

                                        if (focusedInput)
                                            $(focusedInput).click();
                                    }
                                }
                            }
                        }
                    });

                    // Add click event to the body to check if the user clicks outside the panel
                    $('body').on('click', function (e) {
                        // Check that the click isn't in the panel or the drop down button
                        if (e.target.className != 'radiolist' && $(e.target).closest('.radiolist').length == 0 && e.target.className != 'vgr-expand-dropdownListForCitys' && $(e.target).closest('.vgr-expand-dropdownListForCitys').length == 0) {
                            // Hide the panel
                            self.toggleFunction();
                            self.flipExpandedState();
                            self.setScreenReaderText();

                            // Unbind click events
                            $('body').unbind('click');
                            $('.radiolist ul li input[type="radio"]').unbind('click');
                            $(document).unbind('keypress');
                        }
                    });
                }

            }
            else {
                // Dont forget to unbind all events when the panel isn't active!
                $(document).unbind('keyup.escape');
                $('body').unbind('click');
                $('.radiolist ul li input[type="radio"]').unbind('click');
                $(document).unbind('keypress');


            }
            this.tab.focus();
            this.deleteFocus();
        });

        this.tab.keypress((e) => {
            // Firefox har ett eget klick event på 'space' och därför behöver vi inte trigga det manuellt i kod
            var triggerClick = !isFirefox() && e.which === spaceKey;
            if (e.which === enterKey || triggerClick) {
                // preventDefault to avoid default 'click' event of pressing enter on elements such as 'button', 'a' etc. This would cause double-clicks.    
                e.preventDefault();
                this.tab.trigger('click');

                e.target.className += ' active';
                this.tab.focus();

            }
        });
        this.close.keypress((e) => {
            if (e.which === enterKey || e.which === spaceKey) {
                // preventDefault to avoid default 'click' event of pressing enter on elements such as 'button', 'a' etc. This would cause double-clicks.    
                e.preventDefault();
                this.close.trigger('click');

                e.target.className += ' active';
                this.close.focus();

            }
        });

    }
    private deleteFocus = () => {

        $(':button').removeClass('active');
    }
    private flipExpandedState = () => {
        this.isExpanded = !this.isExpanded;

        this.panel.attr('data-isexpanded', this.isExpanded.toString());

        this.tab.attr('aria-expanded', this.isExpanded.toString());
        this.tab.attr('data-isexpanded', this.isExpanded.toString());

        this.close.attr('aria-expanded', this.isExpanded.toString());
        this.close.attr('data-isexpanded', this.isExpanded.toString());

        if (this.isExpanded) {
            this.element.addClass('open');
        } else
            this.element.removeClass('open');
    }

    public triggerClick = () => {
        this.tab.trigger('click');

    }
}

export class MobileMenu {
    private screenReaderText: string;
    private menuItems: Array<MenuItem> = [];
    private toolMenuItems: Array<ToolMenuItem> = [];
    private overlay: JQuery;
    private body: JQuery;
    private html: JQuery;
    private menubtntoggle: Toggle;
    public activeMenuItem: MenuItem;
    private previousActiveElement: HTMLElement;
    private menu: JQuery;
    private menubtn: JQuery;
    private closebtn: JQuery;

    constructor(menu: JQuery, menubtn: JQuery, closebtn: JQuery) {
        this.menu = menu;
        this.menubtn = menubtn;
        this.closebtn = closebtn;
        this.screenReaderText = menu.data('srtext');
        this.overlay = $('.darkened-overlay');
        this.body = $('body');
        this.html = $('html');

        this.addMenuItems(menu);

        menu.find('.tool-navigation__li').each((i, e) => {
            this.toolMenuItems.push(new ToolMenuItem($(e), i));
        });

        this.menubtntoggle = new Toggle(menu, menubtn, menu, null, 'mobilemenu');
        closebtn.attr('role', 'tab');
        closebtn.attr('tabindex', '0');
        closebtn.attr('aria-controls', this.menubtntoggle.guid);
        this.setupEvents();
    }
    private setupEvents = () => {

        $(document).on('mobilemenu-open', () => {
            this.overlay.fadeIn();
            this.body.toggleClass('lock-body');
            this.html.toggleClass('lock-body');
            if (this.activeMenuItem !== undefined)
                this.activeMenuItem.menuItem[0].scrollIntoView();
        });

        $(document).on('mobilemenu-close', () => {
            this.overlay.fadeOut();
            this.body.toggleClass('lock-body');
            this.html.toggleClass('lock-body');
        });

        this.closebtn.click(() => {
            this.menubtntoggle.triggerClick();
        });

        var map = [];

        onkeydown = onkeyup = e => {
            e = e || event as any; // to deal with IE
            map[e.keyCode] = e.type === 'keydown';
        }

        $(document).on('keyup', (e) => {
            var activeElement = $(document.activeElement);
            // lock-body == menu is open
            if (this.body.hasClass('lock-body')) {
                var index = activeElement.parent().parent().data('index') as number;

                // ARROW UP
                if (map[38]) {
                    // Initial focus is on the whole menu OR focus on the close button
                    if (activeElement.hasClass('vgr-mobilemenu') || activeElement[0] === this.closebtn[0]) {
                        this.toolMenuItems[this.toolMenuItems.length - 1].link.focus();
                    }
                    else if (activeElement.hasClass('tool-navigation__link')) {
                        index = activeElement.data('index') as number;
                        if (index === 0) {
                            this.focusPreviousItem(this.menuItems[this.menuItems.length - 1].menuItem.data('index') + 1 as number);
                        } else {
                            this.toolMenuItems[index - 1].link.focus();
                        }
                    } else if (activeElement.hasClass('vgr-mobilemenu__link')) {
                        this.focusPreviousItem(index);
                    }
                    else if (activeElement.hasClass('vgr-mobilemenu__list')) {
                        activeElement.prev().find('a').first().focus();
                    }
                }

                // ARROW DOWN
                if (map[40]) {
                    // Initial focus is on the whole menu
                    if (activeElement.hasClass('vgr-mobilemenu')) {
                        this.closebtn.focus();
                    }
                    // Focus is on the 'Close button'
                    else if (activeElement[0] === this.closebtn[0]) {
                        this.menuItems[0].link.focus();
                    }
                    else if (activeElement.hasClass('vgr-mobilemenu__link')) {
                        this.focusNextItem(index);
                    }
                    else if (activeElement.hasClass('tool-navigation__link')) {
                        index = activeElement.data('index') as number;
                        if (index + 1 === this.toolMenuItems.length) {
                            this.closebtn.focus();
                        } else {
                            this.toolMenuItems[index + 1].link.focus();
                        }
                    }
                    else if (activeElement.hasClass('vgr-mobilemenu__list')) {
                        activeElement.find('a').first().focus();
                    }
                }

                // ARROW RIGHT 
                if (map[39]) {
                    if (activeElement.hasClass('vgr-mobilemenu__link')) {
                        if (this.menuItems[index].hasSubMenu) {
                            activeElement.next().focus();
                        }
                    }
                    else if (activeElement.hasClass('tool-navigation__link')) {
                        index = activeElement.data('index') as number;
                        if (index + 1 === this.toolMenuItems.length) {
                            this.closebtn.focus();
                        } else {
                            this.toolMenuItems[index + 1].link.focus();
                        }
                    }
                }

                // ARROW LEFT
                if (map[37]) {
                    if (activeElement.hasClass('vgr-mobilemenu__leveltoggle')) {
                        activeElement.prev().focus();
                    }
                    else if (activeElement.hasClass('vgr-mobilemenu__list')) {
                        activeElement.prev().find('a').first().focus();
                    }
                    else if (activeElement.hasClass('tool-navigation__link')) {
                        index = activeElement.data('index') as number;
                        if (index === 0) {
                            this.focusPreviousItem(this.menuItems[this.menuItems.length - 1].menuItem.data('index') + 1 as number);
                        } else {
                            this.toolMenuItems[index - 1].link.focus();
                        }
                    }
                }

                //TAB
                if (map[9]) {
                    if (this.previousActiveElement == $('.vgr-mobilemenu__footer .main-navigation__ul li:last-child .tool-navigation__link')[0]) {
                        this.closebtn.focus();
                    }
                    this.previousActiveElement = activeElement[0] as HTMLElement;
                }

            }


        });
    }

    private focusPreviousItem = (index: number) => {
        if (index === 0) {
            return this.closebtn.focus();
        }

        if (this.menuItems[index - 1].link.is(':visible')) {
            return this.menuItems[index - 1].link.focus();
        }
        this.focusPreviousItem(index - 1);
    }

    private focusNextItem = (index: number) => {
        if (index + 1 === this.menuItems.length) {
            // Try to find the first tool menu item
            var footeritem = this.menu.find('.vgr-mobilemenu__footer a').first();
            return footeritem.length === 1 ? footeritem.focus() : this.closebtn.focus();
        }

        if (this.menuItems[index + 1].link.is(':visible')) {
            return this.menuItems[index + 1].link.focus();
        }
        this.focusNextItem(index + 1);
    }

    public addMenuItems = (menu: JQuery) => {
        menu.find('.vgr-mobilemenu__item').each((i, e) => {
            var menuitem = new MenuItem(this, $(e), this.screenReaderText, i);
            this.menuItems.push(menuitem);
            if (menuitem.menuItem.hasClass('vgr-mobilemenu__item--active')) {
                this.activeMenuItem = menuitem;
            }
        });
    }
}

class ToolMenuItem {
    public link: JQuery;
    constructor(toolmenuitem: JQuery, index: number) {
        this.link = toolmenuitem.find('.tool-navigation__link');
        this.link.attr('data-index', index);
    }
}

class MenuItem {
    public hasSubMenu: boolean;
    public openBtn: JQuery;
    public subMenu: JQuery;
    public link: JQuery;
    public menuItem: JQuery;
    public mobileMenu: MobileMenu;
    private hasPopulatedSubMenu: boolean;
    private srtext: string;
    private index: number;

    constructor(mobileMenu: MobileMenu, menuitem: JQuery, srtext: string, index: number) {
        this.link = menuitem.find('a').first();

        var openBtn = menuitem.find('button').first();
        // If this button exists on the list item then the page has children
        if (openBtn.length > 0) {
            this.hasSubMenu = true;
            this.openBtn = openBtn;
            this.setupClickEvent();
        }

        // A sub-menu can exist on page-load if the current page is somewhere down the page tree.
        const submenu = menuitem.find('ul').first();
        if (submenu.length > 0) {
            this.subMenu = submenu;
            this.setupToggleEvent();
        }
        this.menuItem = menuitem;
        this.mobileMenu = mobileMenu;
        this.srtext = srtext;
        this.index = index;
    }

    private setupClickEvent = () => {

        this.openBtn.click(() => {
            // The sub-menu should already be fetched and opened if the current page is a subpage, so then we don't need to do it again
            var alreadyOpened: boolean = this.openBtn.is('[data-startopen]');
            if (!this.hasPopulatedSubMenu && !alreadyOpened) {

                var pageId = this.openBtn.attr('data-pageid');
                var langCode = this.openBtn.attr('data-laguageCode');
                var self = this;
                // Get more menu items and append them to the html
                $.ajax({
                    url: '/api/getmoremenupages',
                    type: 'GET',
                    data: {
                        pageId: pageId,
                        langCode: langCode
                    },
                    dataType: 'json',
                    success(result) {
                        if (result.error) {

                        } else {

                            self.openBtn.parents('li')  // Find the menu item that button exists in
                                .first()                // Make sure we only select the closest li
                                .append(result.html);   // Append the sub-menu structure to the list item     

                            // Let us know that the open menu button has already been pressed and that we should not fetch sub-menu item for this node again
                            self.hasPopulatedSubMenu = true;

                            const submenu = self.menuItem.find('ul').first();
                            if (submenu.length > 0) {
                                self.hasSubMenu = true;
                                self.subMenu = submenu;
                                self.openBtn = self.menuItem.find('button').first();
                                self.setupToggleEvent();

                                // Add recently fetch items to the menu
                                self.mobileMenu.addMenuItems(self.subMenu);
                                // Because the toggle above only sets up the event it won't actually expand unless we call the click event
                                self.openBtn.click();
                                self.openBtn.addClass("active");
                            }
                        }
                    }
                });
            }
        });
    }

    private setupToggleEvent = () => {
        this.subMenu.attr('aria-label', this.srtext + this.link.html());
        var toggle = new Toggle(this.openBtn, this.openBtn, this.subMenu, null, 'slide');
    }
}

interface Link {
    url: string;
    text: string;
}
interface BaseMessage {
    id: string;
    text: string;
    user_reply: string;
    links: Link[];
}
interface TextMessage extends BaseMessage {
    respond_with: "text";
}
interface OptionMessage extends BaseMessage {
    respond_with: "option";
    options: string[];
}
interface Config {
    chatUrl: string;
    initUrl: string;
    isOpen: boolean;
    iconOpen: string;
    iconClose: string;
}
interface Metadata {
    name: string;
    icon_url: string;
}

type Message = TextMessage | OptionMessage;