﻿import $ from "jquery"
import lightSlider from "../vendor/lightslider/dist/js/lightslider.js"

// Initiate light sliders on all visible sliders in the dom
$('.lightslider').filter(':visible').each(function () {
    var self = $(this);
    var slideCount = self.data('slidecount');
    var thumbItems = slideCount;
    // Min thumbs == 3, Max thumbs == 6
    if (slideCount > 6) {
        thumbItems = 6;
    } else if (slideCount < 3) {
        thumbItems = 3;
    }

    // Init
    lightSlider(self, {
        gallery: true,
        item: 1,
        loop: true,
        thumbItem: thumbItems,
        slideMargin: 0,
        enableDrag: true,
        currentPagerPosition: 'left',
        adaptiveHeight: true,
        addClass: 'vgr-slider-w-thumbs',
        nextHtml: '<svg fill="#494746" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/></svg>',
        prevHtml: '<svg fill="#494746" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/></svg>',
        responsive: [
            {
                // Always set thumb items to 3 when below 500px
                breakpoint: 500,
                settings: {
                    thumbItem: 3
                }
            }
        ],
        onSliderLoad: function (el) {
            var lightslider = self.parent().parent();
            // Manually putting a # as href because a-tags without href does not validate or get input focus..
            $('.lSAction > a', lightslider).each(function () {
                var $arrow = $(this);
                $arrow.attr('href', '#');
                // We also need a title for the a tags because they do not contain any text (only icons).
                if ($arrow.hasClass('lSPrev')) {
                    $arrow.attr('title', self.data('previtemtext'));
                    $arrow.attr('alt', self.data('previtemtext'));
                } else {
                    $arrow.attr('title', self.data('nextitemtext'));
                    $arrow.attr('alt', self.data('nextitemtext'));
                }
            });
            var altTexts = [];
            $('.lightSlider > li:not(.clone) img', lightslider).each(function () {
                altTexts.push($(this).attr('alt'));
            });
            $('.lSPager img', lightslider).each(function (index) {
                $(this).attr('alt', altTexts[index]);
            });
        }
    });
});