﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget("customer.scrollToTop", {
    _init: function () {
        var self = this;
        
        window.onscroll = function () { self._scrollFunction() };

        $(this.element[0]).click(function () {
            self._topFunction();
        });
    },
    _scrollFunction: function () {
        
        // When the user scrolls down 100px from the top of the document, show the button
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            this.element[0].style.display = "flex";
        } else {
            this.element[0].style.display = "none";
        }

    },
    // When the user clicks on the button, scroll to the top of the document
    _topFunction: function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
});


