﻿import "../vendor/jquery-ui/jquery-ui";
import $ from "jquery";
import { isChrome } from "./../typescript/Vgr.Util";

// If the page contains any expandable blocks or vgr tabs
if ($("[data-expandblock]").length > 0) {
  // "selectionchange" works differentlt on Chrome and we cannot search/open content of the block.
  if (!isChrome()) {
    // Add an event listener that is called whenever an element is clicked or selected etc.
    // We do this because when a user searches the web page (ctrl+f) and finds a result inside an expandable block, the content should be visible.
    document.addEventListener("selectionchange", function (e) {
      var selection = document.getSelection();

      var anchorNode = selection.anchorNode;

      if (anchorNode === null) return;

      var $expandableContent = $(selection.anchorNode.parentNode).closest(
        "[data-expandblockcontent]"
      );

      // Check that the selected content is inside an expandable block
      if ($expandableContent !== null && $expandableContent.length > 0) {
        var $expandableBlock = $expandableContent.closest("[data-expandblock]");

        // Open the expandable content
        if (!$expandableBlock.hasClass("expand-block--open"))
          $expandableBlock.addClass("expand-block--open");
        $expandableContent.removeClass("expand-block--not-show");

        $expandableContent.attr("aria-expanded", true);
      }
    });
  } else {
  }
}

$(".vgr-toggle__button").click(function () {
  var toggleTexts = $(this).children(".toggle-text").children("span");

  $(toggleTexts).each(function () {
    if ($(this).hasClass("hidden")) {
      $(this).removeClass("hidden");
    } else {
      $(this).addClass("hidden");
    }
  });

  return false; // If the click event is executed the search form will be submitted
});
