﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.teaserBlock', {
    options: {
    },
    variables: {

    },
    // Constructor
    _create: function () {
        var self = this;

        // Hela puffblocket ska bete sig som en länk:
        // a-taggen ligger inuti blocket istället för att wrappa puffblocket för att skrämläsare ska kunna läsa upp länk och innehåll i blocket. Samtidigt kan man snabbt navigera mellan länkar på andra puffblock.
        $(this.element).click(function () {
            var aElem = $('a', self.element);
            if (aElem.length > 0) {
                // När man klickar på blocket går vi till samma sida som a-taggen inuti blocket länkar till
                window.location.href = aElem[0].href;
            }
        });
        
        
    },
    _destroy: function () {
    },
});


