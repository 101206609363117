﻿import $ from "jquery";
import { forceRedrawOfElement } from "./typescript/Vgr.Util";

var language = $("html").attr("lang");

if (language !== "sv")
  // If more langauges should be supported, then add additional language files in BundleConfig
  language = "en";

$.datepicker.setDefaults($.datepicker.regional[language]);

// Initiate datepicker
$(".datepicker").each(function () {
  var self = this;
  $(this).datepicker({
    // When filtering appointments, make sure the "to" is ahead of time than the "from" date
    onSelect: function (dateText) {
      var startDate = new Date($(".start-date").datepicker("getDate"));
      var endDate = new Date($(".end-date").datepicker("getDate"));
      if ($(self).hasClass("start-date")) {
        // If the start date if after the end date
        if (startDate > endDate) {
          // Get the start date and add one month to it, just so that we have something to search for
          var d = new Date(dateText);
          d = new Date(d).addMonths(1);

          // Set the end date to follow the start date
          $(".end-date").datepicker("setDate", d);
        }
      } else if ($(self).hasClass("end-date")) {
        // If the end date if before the start date
        if (endDate < startDate) {
          var d = new Date(dateText);

          // Set the start date to the end date
          $(".start-date").datepicker("setDate", new Date(dateText));
        }
      }
    },

    dayNamesShort: [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ],

    closeText: "Close",
    autoclose: true,
    onClose: removeAria,
  });
});

function removeAria() {
  // make the rest of the page accessible again:
  //$("#wrapper").removeAttr('aria-hidden');
  $(this).focus();
}
var input = $("#ftv-booking-form .input-validation-error:first");

if (input) {
  input.focus(); // Focus on first invalid field
}

// Show loading spinner
$(".appointments-search-form").submit(function (e) {
  $(this).find(".ajax-loader-img").removeClass("hidden");
});

//TODO: could be made to work for all forms, not just ftv. Doing this for now because I dont have time to test all forms. Remeber that the form may be valided by js.
// Prevents the user from subbmitting the same form more than once, by disabling the button
if ($("form").length > 0) {
  $(
    '.ftv-booking input[type="submit"],.ftv-booking button[type="submit"]'
  ).click(function () {
    $(this).attr("disabled", "disabled"); // Disable the button

    // Force browser to redraw/repaint because the button gets hidden in IE 11
    forceRedrawOfElement(this);

    $(this).parents("form").submit(); // As we've interupted the submit event we need to call it again
  });
}

// ESC
$(document).keyup(function (ev) {
  if (ev.which == 27) {
    // stäng tooltip om man trycker på esc
    if ($(ev.target).hasClass("tooltipster")) {
      $(".tooltipster").tooltipster("hide");
    }
    if (
      $(ev.target).parents(".ftv-booking__layout66").length > 0 ||
      $(ev.target).parents(".ftv-booking").length > 0
    ) {
      // om en länk eller knapp har aria-expanded= true och trycker på esc så skall det triggas en klick event för att stänga panelen.
      if ($(ev.target).attr("aria-expanded") == "true") {
        $(ev.target).closest(".vgr-toggle").trigger("click");
        $(ev.target).closest(".vgr-toggle").focus();
      }
      // om man trycker på esc och markören ligger någonstans inne i panelen så ska panelen stängas via ett klick event.
      if ($(ev.target).closest(".vgr-toggle").attr("aria-expanded") == "true") {
        $(ev.target.closest(".vgr-toggle")).find("button").trigger("click");
        $(ev.target.closest(".vgr-toggle")).find("button").focus();
      }
    }
  }
});
