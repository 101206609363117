﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.socialMediaPopup', {
    options: {

    },

    // Constructor
    _create: function () {
        this._setupClickEvents();
    },
    _destroy: function () {
    },
    _setupClickEvents: function () {
        var self = this;
        $('.popup', this.element).click(function (event) {
            var width = screen.width / 2;
            var height = screen.height / 2;
            var left = width - (width / 2);
            var top = height - (height / 2);
            var nWin = window.open($(this).prop('href'), '', 'scrollbars=1,height=' + height + ',width=' + width + ',top=' + top + ', left=' + left);
            if (window.focus) {
                nWin.focus();
            }
            event.preventDefault();
        });
    }
});