﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.languagepickerToggle', {
    options: {

    },
    // Constructor
    _create: function () {
        this._setupClickEvents();
    },
    _destroy: function () {
    },
    _setupClickEvents: function () {

        var $toolNav = $('.language-block__parent-li [data-tab]');
        var $blockdiv = $('.language-block');

        $toolNav.click(function () {
            if ($blockdiv.attr('data-active') === 'active') {
                $blockdiv.removeAttr('data-active');
            }
            else {
                $blockdiv.attr('data-active', 'active');

            }

        });
    }
});