﻿import $ from "jquery";
import "./vendor/jquery-ui/jquery-ui";
import { Toggle, MultiCheckbox, MobileMenu } from "./typescript/Vgr.Ui";

$(".vgr-tabs").tabs();

$("#share-buttons").socialMediaPopup();
$("#cookie-info").cookieInfo();

// Init query completion for organization search
if ($(".organizationsearchcontrolblock #SearchQuery").length > 0) {
  $(".organizationsearchcontrolblock #SearchQuery").organizationQueryCompletion(
    { source: "kiv", header: "Sökordsförslag" }
  );
}

// Init query completion for project search
if ($(".projectsearchblock #SearchQuery").length > 0) {
  $(".projectsearchblock #SearchQuery").organizationQueryCompletion({
    source: "episerver",
    header: "Snabblänkar",
  });
}

$("#search-btn, #toolnavigation-toggle").toggleDataActive();
$("#toolnavigation-toggle").toolNavigationToggle();
$(".language-block--small").languagepickerToggle();
$("[data-expandblock]").expandBlock();

$(".toggle-expanded-block").each(function () {
  new Toggle($(this));
});

$("[data-cascading-ddl]").cascadingDropdownlist();
$(".bestallar-id-control-block").bestallarIdQueryCompletion();

// Select the first invalid input on the contact form or xforms
var input = $(
  ".contact-form.vgr-form .input-validation-error:first,.vgr-form .vgr-form__item--error:first input"
);
if (input.length > 0) {
  // Wait a short while to let the form fully load and focus on first invalid field
  setTimeout(function () {
    input.focus();
  }, 500);
}

new Toggle(
  $(".search"),
  $("#search-btn"),
  null,
  $("#main-search .search-input-box ")
);

$(".vgr-toggle").each(function () {
  new Toggle($(this));
});

// Check if a form has been submitted and if it containts any errors
var hasInvalidField = $(".field-validation-error").length > 0;

// Init multi checkboxes for ftv booking
$(".ftv-booking-multicheckbox.vgr-multicheckbox").each(function () {
  // If the forms containts any erorrs, then we should not reset all checkboxes.
  new MultiCheckbox($(this), !hasInvalidField);
});
$(".projectsearchblock .vgr-multicheckbox").each(function () {
  new MultiCheckbox($(this), false);
});

if (typeof $("#main-menu-nav").accessibleMegaMenu === "function") {
  // The accessible megamenu is only used on public sites
  $("#main-menu-nav").accessibleMegaMenu({
    ///* prefix for generated unique id attributes, which are required
    //   to indicate aria-owns, aria-controls and aria-labelledby */
    //uuidPrefix: "accessible-megamenu",
    ///* css class used to define the megamenu styling */
    //menuClass: "nav-menu",
    ///* css class for a top-level navigation item in the megamenu */
    //topNavItemClass: "nav-item",
    ///* css class for a megamenu panel */
    //panelClass: "sub-nav",
    ///* css class for a group of items within a megamenu panel */
    //panelGroupClass: "sub-nav-group",
    ///* css class for the hover state */
    //hoverClass: "hover",
    ///* css class for the focus state */
    //focusClass: "focus",
    ///* css class for the open state */
    //openClass: "open"
  });
}

// Behåll focus på knappen när man stänger ytan.
$("button").click(function () {
  if ($(this).attr("aria-expanded") == "false") {
    $(this).focus();
  }
});

var $scrollToTop = $("#scrollToTop");
if ($scrollToTop.length > 0) {
  $scrollToTop.scrollToTop();
}

$("nav").removeAttr("role");
new MobileMenu(
  $(".vgr-mobilemenu"),
  $("#mobile-menu-btn"),
  $(".vgr-mobilemenu__closebtn")
);
new Toggle(
  $(".mobile-languagepicker"),
  null,
  $(".language-block--mobile"),
  $(".language-block--mobile"),
  "slide"
);

// Global shortcut to access the search field (CTRL + E)
document.addEventListener("keydown", function (e) {
  if (e.key === "e" && e.ctrlKey) {
    e.preventDefault(); // Prevent browser from doing their own search thing
    var megamenuSearchMenuButton = $(
      "button[aria-owns=vgr-megamenu-search]"
    )[0];
    var megamenuSearch = document.getElementById("vgr-megamenu-search");
    if (megamenuSearchMenuButton && megamenuSearch) {
      megamenuSearch.setAttribute("aria-hidden", "false");
      megamenuSearchMenuButton.setAttribute("aria-expanded", "true");
    }
    var search = $("input[type=search]")[0];
    if (search) search.focus();
  }
});
