﻿import "../vendor/jquery-ui/jquery-ui"
import $ from "jquery";

$.widget('customer.toolNavigationToggle', {
    options: {
        
    },
    // Constructor
    _create: function () {
        this._setupClickEvents();
    },
    _destroy: function () {
    },
    _setupClickEvents: function () {
        var mainNavigation = $('#vgr-main-navigation');

        $(this.element).click(function () {
            mainNavigation.slideToggle();
        });
    }
});